import * as React from 'react';

import iconSecret from 'images/icon-secret.png';
import iconBounce from 'images/icon-bounce.png';
import iconYiggle from 'images/icon-yiggle.png';
import iconPhotovine from 'images/icon-photovine.png';
import iconTreehouse from 'images/icon-treehouse.png';
import iconTwitVid from 'images/icon-twitvid.png';
import iconFliggo from 'images/icon-fliggo.png';
import ProjectSecret from 'components/projects/ProjectSecret'

export enum ProjectId {
  Secret = 'secret',
  Bounce = 'bounce',
  Yiggle = 'yiggle',
  Photovine = 'photovine',
  Treehouse = 'treehouse',
  TwitVid = 'twitvid',
  Fliggo = 'fliggo'
}

export interface Project {
  id: string;
  sprite: any;
  name: string;
  description: string;
  byline: string;
  timeframe: string;
  details: React.ReactNode
}

export let Projects: Project[] = [
  {
    id: ProjectId.Secret,
    sprite: iconSecret,
    name: 'Secret',
    timeframe: '2013-2015',
    description: 'A place for friends to share their personal feelings, anonymously.',
    byline: '15 million users. #1 in 10+ countries.',
    details: <ProjectSecret />
  },
  {
    id: ProjectId.Bounce,
    sprite: iconBounce,
    name: 'Bounce',
    timeframe: '2019',
    description: 'A workflow tool for musicians to get feedback and organize their works-in-progress.',
    byline: 'Currently in open beta',
    details: <ProjectSecret />
  },
  {
    id: ProjectId.Yiggle,
    sprite: iconYiggle,
    name: 'Yiggle',
    timeframe: '2016-2017',
    description: 'A social quote board. Celebrate your friends.',
    byline: 'Currently unreleased',
    details: <ProjectSecret />
  },
  {
    id: ProjectId.Photovine,
    sprite: iconPhotovine,
    name: 'Photovine',
    timeframe: '2011',
    description: 'Plant a photo, watch it grow. A caption-based photo sharing app.',
    byline: '250K users in the first week',
    details: <ProjectSecret />
  },
  {
    id: ProjectId.Treehouse,
    sprite: iconTreehouse,
    name: 'Treehouse',
    timeframe: '2010-2011',
    description: 'One of the first mobile-only photo social networks.',
    byline: 'Helped inspire Instagram. Acqui-hired by Google.',
    details: <ProjectSecret />
  },
  {
    id: ProjectId.TwitVid,
    sprite: iconTwitVid,
    name: 'TwitVid',
    timeframe: '2010',
    description: 'The first platform to enable users to share videos on Twitter.',
    byline: '100K users including Schwarzeneggar, NBA, and many more',
    details: <ProjectSecret />
  },
  {
    id: ProjectId.Fliggo,
    sprite: iconFliggo,
    name: 'Fliggo',
    timeframe: '2008-2010',
    description: 'A white-label platform to instantly create your own video community.',
    byline: 'Accepted into YCombinator',
    details: <ProjectSecret />
  }
];