import React from 'react';
import './App.scss';
import 'react-animate-on-scroll';
import { HashRouter, Switch, Route } from 'react-router-dom';
import HomePage from './routes/HomePage';
import WorkPage from './routes/WorkPage';
import PageContainer from 'components/PageContainer';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const App: React.FC = () => {
  return (
    <HashRouter basename={process.env.PUBLIC_URL}>
      <Route render={({location}) => {
      return <ReactCSSTransitionGroup transitionAppear={true} 
        transitionAppearTimeout={500}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
        transitionName='page'>
        <Switch key={location.pathname} location={location}>
          <Route exact path="/" component={PageContainer(HomePage)} />
          <Route path="/work" component={PageContainer(WorkPage)} />
        </Switch>
      </ReactCSSTransitionGroup>
      }} />
    </HashRouter>
  );
}

export default App;