import React from "react";
import Matter, { Common, IChamfer, Mouse, MouseConstraint, Events, Body, Engine, World, Render, Runner, Composite } from "matter-js";
import { Project, Projects } from 'model/Project';
import {isMobile} from 'react-device-detect';
import classNames from 'classnames';

interface IconPileSceneProps {
  onIconClick: (project?: Project) => void;
  onSwipe: (direction: number) => void;
  className?: string;
}

const targetY = 270 * (isMobile ? 0.7 : 1);
const targetX = window.innerWidth / 2;
const spriteSize = 256;
const bodySize = Math.min(128, window.innerWidth * 0.2);
const targetMaxScale = 1.3;
const targetMinScale = 0.5;
const spriteTargetMaxScale = (bodySize / spriteSize) * targetMaxScale;
const spriteTargetMinScale = (bodySize / spriteSize) * targetMinScale;
const spriteStartingScale = bodySize / spriteSize;
const targetRotation = 0;
const animationDuration = 150;
const scaleFactor = 0.5;
const swipeThreshold = 100;

export default class IconPileScene extends React.Component <IconPileSceneProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  private sceneRef = React.createRef<HTMLDivElement>();
  private _engine = Engine.create();
  private _deviceOrientationEvent: any;
  private _resetTimeout: number = 0;
  private _selectedBody: Body | undefined;
  private _clickedBody: Body | undefined;
  private _shrinkingBodies: Body[] = [];
  private _growingBodies: Body[] = [];
  private _pile: Composite | undefined;
  private _startingArea: number = 0;
  private _scrollOverride: boolean = false;
  private _mouseDownStartPosition?: any;
  private _mouse: any;

  public componentDidMount() {

    this._engine.render = Render.create({
      element: this.sceneRef.current!,
      engine: this._engine,
      options: {
        wireframes: false,
        background: 'rgba(0,0,0,0)',
      },
    });

    this.updateScene();

    window.onresize = () => {
      return;
      // TODO: Figure out how to re-render the canvas or resize the walls / bodies
      clearInterval(this._resetTimeout);

      this._resetTimeout = window.setTimeout(() => {
        this.reset();
        this.updateScene();
      }, 200);
    }
    
    window.addEventListener('deviceorientation', (event: DeviceOrientationEvent) => {
      this._deviceOrientationEvent = event;
      this.updateGravity();
    }, true);

    window.addEventListener('orientationchange', () => {
        this.updateGravity();
    }, false);

  }

  private updateScene() {
    var Engine = Matter.Engine,
      Render = Matter.Render,
      World = Matter.World,
      Composites = Matter.Composites,
      Bodies = Matter.Bodies;

    let world = this._engine.world;

    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;

    this._engine.render.options = {
      width: screenWidth,
      height: screenHeight
    }

    // create runner
    let runner = Runner.create({});
    Runner.run(runner, this._engine);

    let iconCount = -1;
    this._pile = Composites.stack(0, 0, Projects.length, 1, 0, 0, function(x: number, y: number) {
      let chamfer: IChamfer = {
        radius: bodySize / 6
      };

      iconCount++;

      return Bodies.rectangle(Math.random() * screenWidth, Math.random() * -1000, bodySize, bodySize, {
          id: iconCount,
          restitution: 0.6,
          friction: 0.4,
          chamfer: chamfer,
          density: 0.08,
          frictionAir: 0.01,
          render: {
            sprite: {
              texture: Projects[iconCount].sprite,
              xScale: bodySize / 256,
              yScale: bodySize / 256
            }
          }
      });
    });

    let renderOptions = {
      fillStyle: '#fff'
    }

    World.add(world, [
      // walls (t, r, b, l)
      Bodies.rectangle(screenWidth / 2, -1000, screenWidth, 50, { isStatic: true, render: renderOptions }),
      Bodies.rectangle(screenWidth + 30, -1000, 50, screenHeight + 3000, { isStatic: true, render: renderOptions }),
      Bodies.rectangle(screenWidth / 2, screenHeight + 30, screenWidth, 50, { isStatic: true, render: renderOptions }),
      Bodies.rectangle(-30, -1000, 50, screenHeight + 3000, { isStatic: true, render: renderOptions })
    ]);
    World.add(world, this._pile);

    // add mouse control
    this._mouse = Mouse.create(this._engine.render.canvas);
    this._mouse.pixelRatio = window.devicePixelRatio;

    let mouseConstraint = MouseConstraint.create(this._engine, {
        mouse: this._mouse,
        constraint: {
            stiffness: 0.2,
            render: {
                visible: false
            }
        }
    });

    this._engine.render.mouse = this._mouse;

    

    Events.on(mouseConstraint, "mousedown", (e: React.MouseEvent) => {
      this._clickedBody = mouseConstraint.body;
      this._mouseDownStartPosition = {...this._mouse.position};
      if (this._clickedBody && !this._startingArea) {
        this._startingArea = this._clickedBody.area;
      }
    });

    Events.on(mouseConstraint, "mouseup", () => {      
      this.handleClick();
    })

    Events.on(runner, "beforeUpdate", () => {
      let elapsed = runner.delta;

      if (this._selectedBody) {
        if (!this._scrollOverride) {
          // Animate position
          var posDelta = {x: targetX - this._selectedBody.position.x, y: targetY - this._selectedBody.position.y }
          if ((posDelta.x > 1 || posDelta.x < -1) || (posDelta.y > 1 || posDelta.y < -1)) {
            Body.setPosition(this._selectedBody, 
              {x: this._selectedBody.position.x + posDelta.x * elapsed / animationDuration, 
              y: this._selectedBody.position.y + posDelta.y * elapsed / animationDuration});
          }
        }

        // Animate scale up
        if (this._selectedBody.render.sprite!.xScale < spriteTargetMaxScale) {
          let scaleX = 1 + this._selectedBody.render.sprite!.xScale * 0.1;
          let scaleY = 1 + this._selectedBody.render.sprite!.yScale * 0.1;

          Body.scale(this._selectedBody, scaleX, scaleY);
          this._selectedBody.render.sprite!.xScale = this._selectedBody!.render!.sprite!.xScale * scaleX
          this._selectedBody.render.sprite!.yScale = this._selectedBody!.render!.sprite!.yScale * scaleY;

          this._selectedBody.render.opacity = 1.0;
        }

        // Shrink unselected bodies
        Composite.allBodies(this._pile!).forEach((body) => {
          if (body !== this._selectedBody) {
            if (body.render.sprite!.xScale > spriteTargetMinScale) {
              let scaleX = 1 - body.render.sprite!.xScale * 0.3;
              let scaleY = 1 - body.render.sprite!.yScale * 0.3;
              Body.scale(body, scaleX, scaleY);
              body.render.sprite!.xScale = body!.render!.sprite!.xScale * scaleX;
              body.render.sprite!.yScale = body!.render!.sprite!.yScale * scaleY;
              body.render.opacity = 0.3;
            } else {
              // Snap to final scale
              body.render.sprite!.yScale = spriteTargetMinScale;
              body.render.sprite!.xScale = spriteTargetMinScale;
              Body.set(body, {restitution: 0.6, friction: 0.4, density: 0.08, frictionAir: 0.01});
            }
          }
        });

        // Animate rotation to neutral
        if (this._selectedBody.angle < targetRotation - 0.01 || this._selectedBody.angle > targetRotation + 0.01) {
          let delta = targetRotation - this._selectedBody.angle;
          let modifier = delta * elapsed / animationDuration;
          Body.setAngle(this._selectedBody, this._selectedBody.angle > 180 ? this._selectedBody.angle - modifier : this._selectedBody.angle + modifier);
        }
      }

      if (this._shrinkingBodies.length > 0) {
        this._shrinkingBodies.forEach((shrinkingBody) => {
          if (shrinkingBody) {
            // Animate scale down
            let scaleX = 1 - shrinkingBody.render.sprite!.xScale * 0.1;
            let scaleY = 1 - shrinkingBody.render.sprite!.yScale * 0.1;

            if (shrinkingBody.render.sprite!.xScale > bodySize / spriteSize) {
              Body.scale(shrinkingBody, scaleX, scaleY);
              shrinkingBody.render.sprite!.yScale = shrinkingBody!.render!.sprite!.yScale * scaleY;
              shrinkingBody.render.sprite!.xScale = shrinkingBody!.render!.sprite!.xScale * scaleX;
            } else if (shrinkingBody.area > this._startingArea) {
              Body.scale(shrinkingBody, scaleX, scaleY);
            } else {
              shrinkingBody.render.sprite!.yScale = spriteStartingScale;
              shrinkingBody.render.sprite!.xScale = spriteStartingScale;
              let finalScale =  (this._startingArea / shrinkingBody.area);
              Body.scale(shrinkingBody, finalScale, finalScale);
              Body.set(shrinkingBody, {restitution: 0.6, friction: 0.4, density: 0.08, frictionAir: 0.01});

              this._shrinkingBodies.splice(this._shrinkingBodies.indexOf(shrinkingBody), 1);
            }
          }
        });
      }
      
      if (this._growingBodies.length > 0) {
        this._growingBodies.forEach((body) => {
          let scaleX = 1 + scaleFactor * (elapsed / animationDuration);
          let scaleY = 1 + scaleFactor * (elapsed / animationDuration);

          if (body.render.sprite!.xScale < spriteStartingScale) {
            Body.scale(body, scaleX, scaleY);
            body.render.sprite!.xScale = body!.render!.sprite!.xScale * scaleX;
            body.render.sprite!.yScale = body!.render!.sprite!.yScale * scaleY;
            body.render.opacity = 1.0;
          } else {
            body.render.sprite!.xScale = spriteStartingScale;
            body.render.sprite!.yScale = spriteStartingScale;
            //let areaDelta =  (startingArea / body.area);
            //Body.scale(body, areaDelta, areaDelta);
            this._growingBodies.splice(this._growingBodies.indexOf(body), 1);
          }
        });
      }
    });

    World.add(world, mouseConstraint);
    Engine.run(this._engine);
    Render.setPixelRatio(this._engine.render, window.devicePixelRatio);
    Render.run(this._engine.render);
  }

  private updateGravity() {
    if (!this._engine) {
      return;
    }

    let orientation = window.orientation;
    let event = this._deviceOrientationEvent;
    let gravity = this._engine.world.gravity;

    if (orientation === 0) {
        gravity.x = Common.clamp(event.gamma, -90, 90) / 90;
        gravity.y = Common.clamp(event.beta, -90, 90) / 90;
    } else if (orientation === 180) {
        gravity.x = Common.clamp(event.gamma, -90, 90) / 90;
        gravity.y = Common.clamp(-event.beta, -90, 90) / 90;
    } else if (orientation === 90) {
        gravity.x = Common.clamp(event.beta, -90, 90) / 90;
        gravity.y = Common.clamp(-event.gamma, -90, 90) / 90;
    } else if (orientation === -90) {
        gravity.x = Common.clamp(-event.beta, -90, 90) / 90;
        gravity.y = Common.clamp(event.gamma, -90, 90) / 90;
    }
  }

  private reset() {
    World.clear(this._engine.world, false);
    Engine.clear(this._engine);
  }

  public selectProjectByIndex(index: number) {
    this._clickedBody = Composite.allBodies(this._pile!).find(body => {
      return body.id === index;
    });
    this.handleClick(true);
  }

  public dropSelected() {
    //this._selectedBody = undefined;
    this.handleClick();
  }

  public handleClick(ignoreClick?: boolean) {
    // If a swipe is detected, move to next project
    if (this._mouseDownStartPosition) {
      let mouseDelta = this._mouse.position.x - this._mouseDownStartPosition.x;
      if (Math.abs(mouseDelta) > swipeThreshold) {
        this._mouseDownStartPosition = undefined;
        this.props.onSwipe(-(mouseDelta / Math.abs(mouseDelta)));
        return;
      }
    }

    // If a body is selected, toss it aside
    if (this._selectedBody) {
      let plusOrMinus = Math.random() < 0.5 ? -1 : 1
      this._shrinkingBodies.push(this._selectedBody);
      Body.setStatic(this._selectedBody, false);
      Body.set(this._selectedBody, {restitution: 0.6, friction: 0.4, density: 0.08, frictionAir: 0.01});
      Body.applyForce(this._selectedBody, {x: window.innerWidth / 2, y: window.innerHeight / 2}, {x: plusOrMinus * 15, y: 10});
    }
    // If there's a target body, bring it forward
    if (this._clickedBody && this._selectedBody !== this._clickedBody) {
      this._selectedBody = this._clickedBody;
      this._clickedBody = undefined;
      Body.setStatic(this._selectedBody, true);

      if (!ignoreClick) {
        let project = Projects.find((element, index) => { return index === this._selectedBody!.id });
        if (project) {
          this.props.onIconClick(project);
        }
      }
    } else {
      this._selectedBody = undefined;

      if (!ignoreClick) {
        this.props.onIconClick();
      }

      // Grow all the shrunken icons back to size
      this._growingBodies = this._growingBodies.concat(Composite.allBodies(this._pile!).filter(body => { 
        return body !== this._selectedBody && !this._shrinkingBodies.find(shrinkingBody => { return body === shrinkingBody});
      }));
    }
  }

  public moveSelectedBody(top: number) {
    if (this._selectedBody) {
      Body.setPosition(this._selectedBody, {x: this._selectedBody.position.x, y: targetY - top});
      this._scrollOverride = (top > 0);
    }
  }

  render() {
    return <div className={classNames('scene-icon-pile', this.props.className)} ref={this.sceneRef}>{this.props.children}</div>;
  }
}