import * as React from 'react';
import { RouteComponentProps } from 'react-router';

interface PageContainerProps extends RouteComponentProps<any> {}

const PageContainer = (Page: React.ComponentType<PageContainerProps>) => {
  return (props: PageContainerProps) => <div id="container">
  
  
  <Page {...props} />
  
  </div>;
}

export default PageContainer;