import * as React from 'react';
import classNames from 'classnames';

interface PageSectionProps {
  className?: string
  showPattern?: boolean
}

export default class PageSection extends React.Component<PageSectionProps, any> {
  public render() {
    return <div className={classNames('page-section', this.props.className, {'show-pattern': this.props.showPattern})}>
      <div className="page-section-inner">
        {this.props.children}
      </div>
    </div>
  }
}