import * as React from 'react';
// import PageContainer from 'components/PageContainer';
import PageSection from 'components/PageSection';
import { RouteComponentProps } from 'react-router';
import Routes from 'routes/Routes';
// import classNames from 'classnames';

interface HomePageProps extends RouteComponentProps<any> {}

export default class HomePage extends React.Component<HomePageProps, any> {
  public render() {
    return <PageSection className="page-header" showPattern={true}>
        <div className="profile-pic" />
        <h1>Hi, I'm Chrys Bader</h1>
        <h2>I’m a product designer, software engineer, entreprenuer, and angel investor.</h2>
        <div className="button"><button onClick={() => this.props.history.push(Routes.WorkPage)}>Explore my work</button></div>
      </PageSection>
  }
}