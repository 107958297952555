import * as React from 'react';

export default class ProjectSecret extends React.PureComponent {
  render() {
    return (
      <div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus sollicitudin tortor, eu sodales velit sagittis ac. Duis et vehicula lorem, in hendrerit odio. Sed accumsan purus ligula, nec luctus dui fringilla at. Sed ullamcorper eros ac purus dictum auctor. Aenean ac ante fringilla, dignissim ante quis, suscipit metus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed sed rutrum turpis. Sed sodales nisi gravida arcu vulputate aliquam. Mauris interdum lectus vitae libero aliquet, faucibus varius felis ultrices. Etiam consectetur dolor nulla, vulputate auctor nibh tempor non. Nullam iaculis ultrices justo, eget dapibus eros pretium sit amet.</p>
        <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec feugiat, mi varius tincidunt faucibus, nibh nulla auctor nulla, ut pulvinar lectus libero vitae magna. Sed et convallis tellus, quis bibendum odio. Nunc malesuada porta dui, a ullamcorper velit bibendum auctor. Proin et feugiat urna, vel facilisis turpis. Donec nec faucibus nunc. Mauris fringilla quam vel risus gravida egestas. Praesent porttitor eros ut elit mollis, et fringilla risus euismod. Nulla eget mi sapien.</p>
        <p>Praesent pharetra imperdiet mi, eget ultrices enim sodales eu. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam pulvinar nisl at dictum laoreet. Nullam aliquam sollicitudin dictum. Aliquam ullamcorper, sem nec cursus aliquam, ante risus placerat elit, nec tempor purus quam quis tellus. Quisque auctor enim nisl, ullamcorper laoreet mi imperdiet ac. Curabitur imperdiet tincidunt libero, vitae tristique risus laoreet nec. Phasellus laoreet nibh sed imperdiet viverra. Nunc bibendum augue ut est ornare blandit. Pellentesque rhoncus orci leo, sit amet suscipit lectus mollis sit amet.</p>
        <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec fermentum quis metus non varius. Cras auctor ut dui et placerat. Curabitur commodo massa at lorem imperdiet, at elementum mi venenatis. Suspendisse sodales nec turpis vel eleifend. Fusce et nibh sed eros interdum venenatis. Integer et dictum nulla. Praesent non magna massa. Pellentesque commodo quam ac purus hendrerit ultricies. Donec sit amet vulputate est.</p>
        <p>Morbi egestas, mi vel gravida fermentum, lacus tortor efficitur arcu, volutpat ultrices velit nisi ac ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras rhoncus feugiat turpis, sed lobortis sapien lacinia in. Etiam vulputate vel velit ac sagittis. Aliquam a dolor quis risus imperdiet venenatis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam ut nunc vel risus pulvinar congue ac eget nulla. Aliquam sodales varius interdum. Nullam ut porta nibh. Phasellus metus eros, faucibus in quam a, interdum sagittis arcu. Nunc turpis dolor, dapibus et viverra et, convallis quis tellus.</p>
      </div>
    )
  }
}