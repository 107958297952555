import * as React from 'react';
import PageSection from 'components/PageSection';
import ProjectView from 'components/ProjectView';
import IconPileScene from 'components/scenes/IconPileScene';
import { Project, Projects } from 'model/Project';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

interface WorkPageState {
  selectedProject?: Project
  zoomLevel: number;
  isLoaded: boolean;
  shouldMaximize: boolean;
  nextPressed: boolean;
  prevPressed: boolean;
  cycleDirection?: 'left' | 'right';
}

interface WorkPageProps extends RouteComponentProps<any> {}

export default class WorkPage extends React.Component<WorkPageProps, WorkPageState> {
  private sceneRef = React.createRef<IconPileScene>();

  constructor(props: any) {
    super(props);
    this.state = {
      zoomLevel: 0,
      isLoaded: false,
      shouldMaximize: false,
      nextPressed: false,
      prevPressed: false
    }
  }

  private showProject(project?: Project) {
    //this.props.history.push('/work' + (project && '/' + project.id));
    this.setState({
      selectedProject: project,
      zoomLevel: project ? 1 : 0,
      shouldMaximize: (this.state.selectedProject !== undefined && project === undefined),
    });

    window.setTimeout(() => {
      this.setState({shouldMaximize: false, cycleDirection: undefined})
    }, 300);
  }

  public componentDidMount() {
    this.setState({isLoaded: true});
    document.addEventListener("keydown", this.handleKeyDown.bind(this), false);
  }

  public componentWillUnmount() {
    this.showProject();
    document.removeEventListener("keydown", this.handleKeyDown.bind(this), false);
  }

  private cycleProject(direction: number) {
    if (this.state.selectedProject && this.sceneRef.current) {
      let index = Projects.findIndex(element => {
        return element.id === this.state.selectedProject!.id;
      });

      if (index > -1) {      
        let nextIndex = index + direction > Projects.length - 1 ? 0 : (index + direction < 0 ? Projects.length - 1 : index + direction);
        this.sceneRef.current.selectProjectByIndex(nextIndex);
        this.setState({cycleDirection: direction > 0 ? 'right' : 'left'}, () => {
          this.showProject(Projects[nextIndex]);
        });
      }
    }
  }

  private handleKeyDown(event: KeyboardEvent) {
    switch (event.which) {
      case 37: { // Left arrow
        this.cycleProject(-1);
        this.setState({prevPressed: true});
        window.setTimeout(() => {
          this.setState({prevPressed: false});
        }, 100);
        break;
      }
      case 39: { // Right arrow
        this.cycleProject(1);
        this.setState({nextPressed: true});
        window.setTimeout(() => {
          this.setState({nextPressed: false});
        }, 100);
        break;
      }
      case 27: { // Escape
        this.onProjectClose();
        break;
      }
      default: {
        // no-op
      }
    }
  }

  private onProjectClose() {
    if (this.sceneRef.current) {
      this.showProject();
      this.sceneRef.current.dropSelected();
    }
  }

  private onProjectScroll(scrollTop: number) {
    if (this.sceneRef.current) {
      this.sceneRef.current.moveSelectedBody(scrollTop);
    }
  }

  public render() {
    return <IconPileScene ref={this.sceneRef} 
              className={classNames('zoom-' + this.state.zoomLevel)}
              onIconClick={(project) => this.showProject(project)} 
              onSwipe={(direction) => {this.cycleProject(direction)}}>
        <PageSection className={classNames('page-work', {'minimized': this.state.selectedProject, 'maximized': this.state.shouldMaximize, 'initial': !this.state.isLoaded})} showPattern={true}>
          <div className="profile-pic" onClick={() => this.showProject()} />
          <h2>Throughout my 20 years of experience, my products have reached over 15 million people in 100+ countries.</h2>
          <p className="byline">Click on an app to learn more</p>
        </PageSection>
        <div className={classNames("next-page", {"next-page-visible": this.state.selectedProject, "active": this.state.nextPressed})} onClick={() => this.cycleProject(1)}></div>
        <div className={classNames("prev-page", {"prev-page-visible": this.state.selectedProject, "active": this.state.prevPressed})} onClick={() => this.cycleProject(-1)}></div>
        <ReactCSSTransitionGroup transitionAppear={true} 
        transitionAppearTimeout={500}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
        transitionName={'project' + (this.state.cycleDirection !== undefined ? '-' + this.state.cycleDirection : '')}>
        {this.state.selectedProject && <ProjectView 
          key={this.state.selectedProject!.id}
          project={this.state.selectedProject} 
          onScroll={this.onProjectScroll.bind(this)}
          onClose={this.onProjectClose.bind(this)}
        />  }
          </ReactCSSTransitionGroup>
      </IconPileScene>
  }
}