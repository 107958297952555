import * as React from 'react';
import { Project } from 'model/Project';
import 'scss/project.scss';
import classNames from 'classnames';

interface ProjectViewProps {
  project?: Project
  onScroll: (scrollTop: number) => void;
  onClose: () => void;
}

interface ProjectViewState {
  project?: Project;
  reset: boolean;
}

export default class ProjectView extends React.Component<ProjectViewProps, ProjectViewState> {

  private projectRef = React.createRef<HTMLDivElement>();

  constructor(props: ProjectViewProps) {
    super(props);
    this.state = {
      reset: false,
      project: props.project && props.project
    }
  }

  private handleClick(e: React.MouseEvent) {
    if (this.projectRef.current) {
      if (this.projectRef.current.scrollTop + e.clientY < 300) {
        this.props.onClose();
      }
    }
  }

  private handleScroll() {
    if (this.projectRef.current) {
      this.props.onScroll(this.projectRef.current.scrollTop);
    }
  }

  public render() {
    return <div ref={this.projectRef} className={classNames('page-section project')} onScroll={() => this.handleScroll()} onClick={(e) => this.handleClick(e)}>
        <h1>{this.state.project && this.state.project.name}</h1>
        <p className="timeframe">{this.state.project!.timeframe}</p> 
        <h2>{this.state.project && this.state.project.description}</h2>
        <p className="byline">{this.state.project && this.state.project.byline}</p>
        {this.state.project && false && <div className="project-details">
          {this.state.project!.details}
        </div>}
    </div>
  }
}